<template>
  <el-dialog
      title="鉴定结果"
      :visible.sync="dialogVisible"
      width="1000px"
      class="dialogForm"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose">
    <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="100px" :label-position="'top'"
             size="small">
      <el-form-item label="藏品鉴定情况" prop="qingk">
        <div class="flex_b_c">
          <div class="flex_a_c">
            <div class="flex_a_c">
              <div class="labelName text_right">藏品名称</div>
              <el-input class="lanelCont" v-model.trim="search.collectionName"
                        placeholder="请输入藏品名称(限50字)"
                        maxlength="50" clearable></el-input>
            </div>
            <div class="flex_a_c">
              <div class="labelName text_right">藏品编号</div>
              <el-input class="lanelCont" v-model.trim="search.collectionId"
                        placeholder="请输入藏品编号(限50字)"
                        maxlength="50" clearable></el-input>
            </div>
          </div>
          <div>
            <el-button size="small" type="primary" @click="getDataList()" icon="el-icon-search">
              查询
            </el-button>
            <el-button size="small" @click="reseting(1)" icon='el-icon-refresh-right'>重置</el-button>
          </div>
        </div>
      </el-form-item>
      <div class="tabsCls flex_b_c">
        <el-tabs size="small" v-model="search.appraisalLevel" @tab-click="handleClick">
          <el-tab-pane v-for="item in leaveList" :key="item.level" :label="item.levelName2"
                       :name="item.level"></el-tab-pane>
        </el-tabs>
        <div>
          <el-button size="small" @click="cataloging()" icon="el-icon-edit">
            批量著录
          </el-button>
        </div>
      </div>
      <el-table
          :data="dataList"
          size="small"
          :row-key="'dataId'"
          @selection-change="selectionChangeHandle"
          v-loading="loading"
          ref="multipleTable"
          height="300px"
          class="table"
      >
        <el-table-column :reserve-selection="true" type="selection" width="50"/>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="name" label="图标" width="120px">
          <template slot-scope="scope">
            <div class="flex_a_c">
              <div v-if="scope.row.carded == 0"
                   class="icon-wenwukapian iconfont listIcon"></div>
              <el-image
                  style="width: 36px; height: 36px"
                  :src="scope.row.picMasterViewUrl"
                  :fit="'cover'"
                  :preview-src-list="[scope.row.picMasterViewUrl]"
              >
                <div slot="error" class="image-slot">
                  <el-image
                      style="width: 36px; height: 36px"
                      :src="require('@/assets/img/default.png')"
                      :fit="'cover'">
                  </el-image>
                </div>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="collectionName" label="藏品名称"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="archivesBasicName" label="藏品分类"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="collectionLevel" label="级别" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ getLevelData(scope.row.collectionLevel) }}
          </template>
        </el-table-column>
        <el-table-column prop="appraisalLevel" label="鉴定级别" width="160px">
          <template slot-scope="scope">
            <el-select v-model="scope.row.appraisalLevel" size="small"
                       @change="modifyLevel(scope.row)"
                       placeholder="请选择鉴定级别"
                       style="width: 100%"
                       clearable>
              <el-option
                  v-for="item in leaveList"
                  :key="item.level"
                  :label="item.levelName"
                  :value="item.level">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center" style="padding-bottom: 15px">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="search.current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="search.size"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>

      <el-form-item label="鉴定文件" prop="netUrl">
        <resume-upload :dir="'collection'" ref="resume-upload" :filtButton="true" :filtButtonName="'上传鉴定文件'"
                       :upFileSize="upFileSize" :uploadNum="flieNumber" :allowFiles="fileType"
                       @upFileDate="(data)=>getUpData(data)"></resume-upload>
        <div v-for="(item,index) in fileList">
          <el-card class="box-card">
            <div class="flex_b_c">
              <div class="el-icon-notebook-2" @click="previewPdf(item.netUrl)"></div>
              <div style="flex: 1;margin: 0 20px" @click="previewPdf(item.netUrl)">
                <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                  <div class="omit">{{ item.fileName }}</div>
                </el-tooltip>
              </div>
              <div class="el-icon-circle-close" style="cursor: pointer"
                   @click="deteliFile(item,index)"></div>
            </div>
          </el-card>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="text_center">
      <el-button size="small" @click="handleClose()">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()">保存</el-button>
    </div>

    <el-dialog
        title="上传鉴定级别"
        :visible.sync="visible"
        width="500px"
        append-to-body
        class="dialogTable"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="visibleClose">
      <el-table
          :data="leaveList"
          size="small"
          class="table"
      >
        <el-table-column prop="levelName" label="文物级别" show-overflow-tooltip></el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button size="mini" type="text"
                       @click="choice(scope.row)">
              选择
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center" style="padding-top: 20px">
        <el-button size="small" @click="visibleClose()">关闭</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import ResumeUpload from "@/components/Upload/resumeUpload.vue";
import {Base64} from 'js-base64'

export default {
  name: "uploadResults",
  components: {ResumeUpload},
  data() {
    return {
      dialogVisible: false,
      seleData: {},
      inputForm: {
        netUrl: '',
        qingk: '1',
      },
      rules: {
        netUrl: [
          {required: true, message: "请上传鉴定文件", trigger: "change"},
        ],
        qingk: [
          {required: true, message: "请上传鉴定文件", trigger: "change"},
        ],
      },

      //文件上传
      flieNumber: 1,
      upFileSize: 200,
      fileList: [],
      fileType: ['PDF'],
      search: {
        appraisalId: '',
        collectionName: '',
        collectionId: '',
        appraisalLevel: '',
        current: 1,
        size: 10,
      },
      dataList: [],
      dataListSelect: [],
      loading: false,
      leaveList: [],
      total: 0,

      visible: false,
    }
  },
  mounted() {
    this.$axios(this.api.collection.listLevelSelect).then(data => {
      if (data.status) {
        this.leaveList = data.data
        this.search.appraisalLevel = this.leaveList[0].level
        this.leaveList.forEach(item => {
          if (item.level == 0) {
            this.$set(item, 'levelName2', '未著录')
          } else {
            this.$set(item, 'levelName2', item.levelName)
          }
          item.level = item.level + ''
        })
      }
    })
  },

  methods: {
    init(row) {
      this.seleData = row
      this.search.appraisalId = row.appraisalId
      this.dialogVisible = true
      this.search.appraisalLevel = this.leaveList[0].level
      this.$nextTick(() => {
        this.getDataList(1, 1)
      })
    },

    getDataList(type, dividePage) {
      if (type == 1) {
        this.search.current = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      this.$axios(this.api.collection.collectionappraisaldataList, this.search, 'get').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.total = parseInt(res.data.total)
          if (this.dataList && this.dataList.length == 0 && this.search.current > 1) {
            this.search.current -= 1
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    reseting() {
      this.search.collectionName = ''
      this.search.collectionId = ''
      this.getDataList(1, 1)
    },

    handleClick(tab, event) {
      this.getDataList(1, 1)
    },

    getLevelData(levelNum) {
      let row = this.leaveList.filter(item => {
        return item.level == levelNum
      })
      if (row && row.length) {
        return row[0].levelName
      }
    },

    //保存修改的鉴定等级
    modifyLevel(row,level) {
      let dataListL
      if (row) {
        dataListL = {
          // appraisalId:this.search.appraisalId,
          appraisalLevel: row.appraisalLevel,
          dataIds: [row.dataId]
        }
      }else {
        let ids = this.dataListSelect.map(item=>{return item.dataId })
        dataListL = {
          // appraisalId:this.search.appraisalId,
          appraisalLevel: level,
          dataIds: ids,
        }
      }
      this.$axios(this.api.collection.collectionappraisaldataUpdateById, dataListL, 'put').then((res) => {
        if (res.status) {
          this.getDataList('', 1)
        }
      })
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 每页数
    sizeChangeHandle(val) {
      this.search.size = val;
      this.getDataList(1, 1)
    },
    // 当前页
    currentChangeHandle(val) {
      this.search.current = val;
      this.getDataList('', '');
    },

    //批量著录
    cataloging() {
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      this.visible = true
    },

    //选择
    choice(row) {
      this.modifyLevel('',row.level)
      this.visibleClose()
    },

    visibleClose() {
      this.visible = false
    },

    getUpData(data) {
      this.fileList = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          return item.upDate
        } else {
          return item
        }
      })
      this.inputForm.netUrl = this.fileList[0].netUrl
    },

    //pdf预览
    previewPdf(url) {
      this.pdfUrl = url
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(this.pdfUrl)));
    },

    //删除文件
    deteliFile(row, index) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fileList.splice(index, 1)
        this.inputForm.netUrl = ''
        this.$refs["resume-upload"].deleteFile(row.fileID ? row.fileID : row.id)
      }).catch(() => {
      })
    },

    //保存
    doSubmit() {
      this.$refs.inputForm.validate((valid) => {
        if (valid) {
          this.$axios(this.api.collection.collectionappraisalresultBatchSave, {
            appraisalId:this.search.appraisalId,
            fileFormat:this.fileList[0].fileFormat,
            fileName:this.fileList[0].fileName,
            filePath:this.fileList[0].filePath,
            fileMd5:this.fileList[0].fileMd5,
            netUrl:this.fileList[0].filePath,
            fileSize: this.fileList[0].fileSize,
            fileSizeFormat: this.fileList[0].fileSizeFormat,
            fileType: 4,
          }, 'post').then((res) => {
            if (res.status) {
              this.$message.success('保存成功')
              this.$emit('refresh')
              this.handleClose()
            } else {
              this.$confirm(`保存失败!（原因：本批藏品鉴定清单中存在未上传鉴定级别数据）`, '温馨提示', {
                confirmButtonText: '查看未著录鉴定级别清单',
                cancelButtonText: '关闭',
                type: 'warning'
              }).then(() => {
                this.search.appraisalLevel = '0'
                this.reseting()
              })
            }
          })
        }
      });
    },

    handleClose() {
      if (this.fileList.length) {
        this.$refs["resume-upload"].deleteFile(this.fileList[0].fileID ? this.fileList[0].fileID : this.fileList[0].id, '', 1)
        this.fileList.splice(0, 1)
      }
      this.$refs.inputForm.resetFields()
      this.dialogVisible = false
    },
  },
}
</script>

<style scoped>
.box-card ::v-deep .el-card__body {
  padding: 10px !important;
}

.labelName {
  width: 100px;
  padding-right: 15px;
}

.lanelCont {
  width: 200px;
}
</style>
